import { NgModule } from '@angular/core';


@NgModule({
  providers:[

  ]
})

export class ConfigCordovaModule { }
