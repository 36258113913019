import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { StorageService } from '@core/services/storage.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private navCtrl: NavController,
    private storage: StorageService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.storage.get('oToken').then(res => {
      if (!res) {
        this.navCtrl.navigateRoot('user/login');
        return false;
      }
      return true;
    });
  }
}
