import { Injectable } from "@angular/core";
import { GigyaService } from "ngx-gigya";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable } from "rxjs";

import * as actions from '@store/actions';
import { StoreState } from "@store/store.state";
import { UtilsService } from "@core/services/utils.service";
import { StorageService } from "@core/services/storage.service";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment.prod';

const env = environment.sap;

@Injectable({
  providedIn: 'root'
})
export class SapService {
  customLangParams = {
    account_is_disabled: 'Por favor, contacte nosso suporte',
    password_must_contain_at_least: 'Sua senha precisa conter ao menos 6 dígitos',
    num_characters_total: '%num this is also custom text',
    password_does_not_meet_complexity_requirements: 'Verifique a complexidade de sua senha',
    this_field_is_required: 'Este campo é obrigatório %fieldname',
    invalid_login_or_password: 'Verifique seu usuário e senha'
  };

  fieldMask = {
    username: '',
    'profile.phones.number': '(xx) x xxxx-xxxx',
    'profile.zip': 'xxxxx-xxx',
  };

  private token$: BehaviorSubject<string> = new BehaviorSubject(null);
  setToken = (token: string) => this.token$.next(token);
  getToken = (): Observable<string> => this.token$.asObservable();

  constructor(
    private http: HttpClient,
    private gigya: GigyaService,
    private uService: UtilsService,
    private store: Store<StoreState>,
    private storageService: StorageService,
  ) {}

  screenLoadLogin(): any {
    this.gigya.accounts.addEventHandlers({
      onLogin: (oAccountData) => {
        const params = {
          UID: oAccountData.UID, fields: 'data.CPFNumber, username',
          callback: async (success) => this.loginSuccess(success)
        };
        this.gigya.accounts.getAccountInfo(params);
        this.gigya.accounts.getJWT(params);
      }
    });
  }

  screenLogin(params: any) {
    this.gigya.accounts.showScreenSet(params);
  }

  screenSet(params: any): void {
    this.gigya.accounts.showScreenSet(params);
  }

  changePassword(){}

  async getDataInfoToken(): Promise<void> {
    const user = await this.storageService.get('oUser');
    if (user) {
      const params = {
        UID: user.UID, fields: 'data.CPFNumber, username',
        callback: async (user: any): Promise<void> => {
          if ( user.id_token ) {
            const token = user.id_token;
            this.setToken(token);
            await this.storageService.set('oToken', user);
            this.store.dispatch(actions.ddvLoad({ token }));
            this.store.dispatch(actions.numSorteLoad({ token }))
          }
        }
      };
      this.gigya.accounts.getJWT(params);
    }
  }

  async getDataInfoTokenByAccount(all=true): Promise<void> {
    const user = await this.storageService.get('oUser');
    if (user) {
      const params = {
        UID: user.requestParams.UID, fields: 'data.CPFNumber, username',
        callback: async (user: any): Promise<void> => {
          if ( user.id_token && all ) {
            const token = user.id_token;
            this.setToken(token);
            await this.storageService.set('oUser', user);
            this.store.dispatch(actions.ddvLoad({ token }));
            this.store.dispatch(actions.numSorteLoad({ token }))
          } else {
            await this.storageService.set('oUser2', user);
          }
        }
      };
      if (all) {
        this.gigya.accounts.getJWT(params);
        this.gigya.accounts.getAccountInfo(params);
      } else {
        this.gigya.accounts.getJWT(params);
      }
    }
  }


  async refreshToken(): Promise<void> {
    const user = await this.storageService.get('oUser');
    if (user) {
      const params = {
        UID: user.requestParams.UID, fields: 'data.CPFNumber, username',
        callback: async (user) => this.loginSuccess(user)
      };
      this.gigya.accounts.getJWT(params);
      this.gigya.accounts.getAccountInfo(params);
    }
  }

  async logout() {
    this.gigya.accounts.addEventHandlers({
      onLogout: (oAccountData) => {
        const params = {
          callback: async () => {
            await this.storageService.remove('oToken');
            await this.storageService.remove('oUSer');
            this.uService.navigate('');
          }
        };
        this.gigya.accounts.logout(params);
      }
    });
  }

  private async loginSuccess (user: any): Promise<void> {
    console.log(user);
    if ( user.id_token ) {
      const token = user.id_token;
      await this.storageService.set('oToken', user);
      this.store.dispatch(actions.ddvLoad({ token }));
      this.uService.navigate('/pages/home');
    } else {
      await this.storageService.set('oUser', user);
    }
  }


  private onAfterScreenLogin = (ev) => {
    const username: any = document.getElementById('gigya-loginID-65995790117118050');
    const password: any = document.getElementById('gigya-password-150355134847394530');
    username.setAttribute('inputmode', 'numeric');
    username.setAttribute('maxlength', '11');
    password.setAttribute('inputmode', 'numeric');
    password.setAttribute('maxlength', 6);
    password.setAttribute('minlength', 6);
  }

  private async onFieldChangedLogin(input: any): Promise<void> {
    if (input.field === 'loginID') {
      this.validationNumber(input.value, 'gigya-loginID-65995790117118050');
    }
    else if (input.field === 'password') {
      this.validationNumber(input.value, 'gigya-password-150355134847394530');
    }
  }

  async validationNumber(value: string, inputId: string): Promise<void> {
    if (!this.parseNumber(value)) {
      await this.uService.alert({
        header: 'Erro', message: 'Aceita só números...', mode: 'ios',
        buttons: ['OK']
      });
      const input: any = document.getElementById(inputId);
      input.value = '';
    }
  }

  parseNumber(value: string): boolean {
    const regex = /[0-9]|\./;
    return regex.test(value);
  }

  private removeCustomMask(fieldValue: string) {
    const regList = [ /\-/gi, /\(/gi, /\)/gi, /\ /gi, /\//gi, /\./gi, /\,/gi, ];
    let field = fieldValue;
    regList.forEach(reg => field =  field?.replace(reg, ''));
    return field;
  }

  getAddressFromCep(cep: string) {
    const re = /\-/gi;
    return this.http.get(`https://viacep.com.br/ws/${cep.replace(re, '')}/json/`).toPromise();
  }
}