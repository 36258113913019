import { Component, Input, OnInit } from '@angular/core';
import { SapService } from '@core/services/sap.service';
import { StorageService } from '@core/services/storage.service';
import { UtilsService } from '@core/services/utils.service';
import { environment } from 'src/environments/environment.prod';

const env = environment.sap;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit {
  @Input() header = true;
  constructor(
    private sapService: SapService,
    private uService: UtilsService,
    private storage: StorageService
  ) { }

  ngOnInit() {
    this.loadForm();
  }

  loadForm() {
    const options = { message: 'Salvando seus dados' };
    const params = {
      containerID: 'profile',
      startScreen: env.profileUpdate.container.profileUpdate,
      screenSet: `${ env.prefixed }-${ env.profileUpdate.sufixed }`,
      customLang: this.sapService.customLangParams,
      onFieldChanged: (ev: any) => this.validate(ev),
      onBeforeSubmit: async () => this.uService.load(options),
      onAfterSubmit: async () => {
        this.uService.loadDimiss();
        this.uService.modalDimiss();
        await this.sapService.getDataInfoTokenByAccount();
      },
    };
    this.sapService.screenSet(params);
  }

  onClose() {
    this.uService.modalDimiss();
  }

  async updateAccountInfo() {
    const user = await this.storage.get('oUser');
    if (user) {
      this.sapService.screenLoadLogin();
    }
  }

  private async validate(ev: any): Promise<void> {
    const inputField: any = document.getElementsByName(ev.field);
    if (ev.field === 'profile.zip') {
      if (ev?.value?.length > 8) {
        await this.uService.load({ message: 'Processando... ', duration: 1000});
        await this.parseData(ev);
      }
    }
  }

  private async parseData(ev: any) {
    const address: any = await this.sapService.getAddressFromCep(ev.value);
    const street: any = document.getElementsByName('data.address.street');
    const city: any = document.getElementsByName('data.address.city');
    const district: any = document.getElementsByName('data.address.district');
    const state: any = document.getElementsByName('data.address.state');
    street.forEach(streetField => streetField.value = address?.logradouro);
    city.forEach(citytField => citytField.value = address?.localidade);
    district.forEach(districtField => districtField.value = address?.bairro);
    state.forEach(stateField => stateField.value = address?.uf);
  }
}
