import { numSorteError } from './../actions/numero-sorte.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';

import * as actions from '@store/actions';
import { MasterService } from '@core/services/master.service';

@Injectable()
export class NumeroSorteEffects {
  service$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.numSorteLoad),
      mergeMap(({ token }) => this.getData(token)
        .pipe(
          map((items) => actions.numSorteSuccess({ items })),
          catchError(async ({ error }) => actions.numSorteError({ error }))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private ms: MasterService,
  ) {}

  getData(token: string) {
    const data$ = this.ms.getAuth('marketing/mobile/numero-sorte', token)
    .pipe(map((res: any) => res.data));
    return data$;
  }
}
