import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { StorageService } from "@core/services/storage.service";
import { Store } from '@ngrx/store';
import * as actions from '@store/actions';
import { StoreState } from "@store/store.state";

@Injectable({
  providedIn: 'root'
})
export class InitService {
  constructor(
    private store: Store<StoreState>,
    private storage: StorageService,
    private router: Router,
  ) {
  }

  async appInit(): Promise<void> {
    // this.store.dispatch(actions.loadLogos());
    await this.getDDV();
  }

  getLogos() {
    this.store.dispatch(actions.loadLogos());
  }

  async getDDV(): Promise<void> {
    const token = await this.storage.get('oToken');
    if (token) {
      this.store.dispatch(actions.ddvLoad({ token: token.id_token }));
    }
  }
}