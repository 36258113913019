import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions';

export interface DdvState { items: any; error: any; loading: boolean; }

export const ddvState: DdvState = { loading: false, items: null, error: null };

const ddvReducerMap = createReducer(
  ddvState,

  on(actions.ddvLoad, (state, { token }) =>
    ({ ...state, loading: true, token })),

  on(actions.ddvSuccess, (state, { items }) =>
    ({ ...state, loading: false, items })),

  on(actions.ddvError, (state, { error }) =>
    ({ ...state, loading: false, error })),
);

export const ddvReducer = (state: any, action: any) =>
  ddvReducerMap(state, action);