import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions';

export interface NumSorteState { items: any; error: any; loading: boolean; }

export const numSorteState: NumSorteState = { loading: false, items: null, error: null };

const nsReducerMap = createReducer(
  numSorteState,

  on(actions.numSorteLoad, (state, { token }) =>
    ({ ...state, loading: true, token })),

  on(actions.numSorteSuccess, (state, { items }) =>
    ({ ...state, loading: false, items })),

  on(actions.numSorteError, (state, { error }) =>
    ({ ...state, loading: false, error })),
);

export const nsReducer = (state: any, action: any) =>
  nsReducerMap(state, action);