import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as actions from '@store/actions';

@Injectable()
export class UsersEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadUser),
      map(({ user }) =>
        actions.loadedUser({ user })),
      catchError(() => EMPTY)
    )
  );

  constructor(
    private actions$: Actions
  ) {}

}
