import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as actions from '@store/actions';
import { MasterService } from '@core/services/master.service';

@Injectable()
export class DdvEffects {
  service$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.ddvLoad),
      mergeMap(({ token }) => this.ms.getDDV(token)
        .pipe(
          map((items) => actions.ddvSuccess({ items })),
          catchError(async ({ error }) => actions.ddvError({ error }))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private ms: MasterService,
  ) {}

}
