import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import * as actions from '@store/actions';
import { MasterService } from '@core/services/master.service';

@Injectable()
export class LogosEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadLogos),
      mergeMap(() => this.ms.getLogos()
        .pipe(
          map((items) => actions.loadedLogos({ items })),
          catchError(async ({ error }) => actions.errorLogos({ error }))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private ms: MasterService
  ) {}
}
