import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Browser } from '@capacitor/browser';
import { UtilsService } from '@core/services/utils.service';
import { MasterService } from '@core/services/master.service';
import { Observable, timer } from 'rxjs';
import { MarkdownService } from 'ngx-markdown';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-v2-regulamento',
  templateUrl: './regulamento.component.html',
  styleUrls: ['./regulamento.component.scss'],
})
export class RegulamentoV2Component implements OnInit {
  @Input() title: string;
  item$: Observable<object>;
  loading = true;

  constructor(
    private ms: MasterService,
    private uService: UtilsService,
    private mdService:MarkdownService,
    ) { }

  ngOnInit() {
    timer(500).subscribe(() => {
     this.getData();
    })
  }

  getData() {
    if (this.title === 'FAQ') {
      this.item$ = this.ms.getPainelMaster('/blogs/8');
    } else {
      this.item$ = this.ms.getPainelMaster('/blogs/6');
    }
  }



  onClose() {
    this.uService.modalDimiss();
  }

}
