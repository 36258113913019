import { NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';

import { LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { ConfigStoreModule } from '@store/config-store.module';
import { ConfigCoreModule } from '@core/modules/config-core.module';
import { ConfigCordovaModule } from '@core/modules/config-cordova.module';
import { MenuPainelModule } from '@core/widgets/menu-painel/menu-painel.module';
import { InitService } from '@core/services/init.service';
import { AppRoutes } from 'src/app/app.routes';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutes,
    CommonModule,
    MenuPainelModule,
    ConfigCoreModule,
    ConfigStoreModule,
    ConfigCordovaModule,
    IonicModule.forRoot(),
    NgxMaskModule.forRoot(),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    InitService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: 'googleTagManagerId', useValue: 'GTM-NN8MXDH' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
