import { Component, OnInit } from '@angular/core';
import { SapService } from '@core/services/sap.service';
import { UtilsService } from '@core/services/utils.service';
import { environment } from 'src/environments/environment.prod';

const env = environment.sap;
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {

  constructor(
    private uService: UtilsService,
    private sapService: SapService,
  ) {
    this.sapService.changePassword();
  }

  ngOnInit() {
    this.loadForm();
  }

  loadForm() {
    const options = { message: 'Salvando seus dados' };
    const params = {
      containerID: 'change-password',
      startScreen: env.profileUpdate.container.resetPassword,
      screenSet: `${ env.prefixed }-${ env.profileUpdate.sufixed }`,
      customLang: this.sapService.customLangParams,
      onBeforeSubmit: async () => this.uService.load(options),
      onAfterSubmit: () => {
        this.uService.loadDimiss();
        this.uService.modalDimiss();
      },
      onAfterScreenLoad: (ev: any) => this.onAfterScreenChangePassword(ev),

    };
    this.sapService.screenSet(params);
  }

  onClose(): Promise<boolean> {
    return this.uService.modalDimiss();
  }

  private onAfterScreenChangePassword = (ev) => {
    const pwd1: any = document.getElementById('gigya-password-newPassword');
    const pwd2: any = document.getElementById('gigya-password-passwordRetype');
    pwd1.setAttribute('inputmode', 'numeric');
    pwd1.setAttribute('maxlength', 6);
    pwd1.setAttribute('minlength', 6);
    pwd1.after("Apenas números");
    pwd2.setAttribute('inputmode', 'numeric');
    pwd2.setAttribute('maxlength', 6);
    pwd2.setAttribute('minlength', 6);
    pwd2.after("Apenas números");
  }

}
