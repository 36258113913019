import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  async set(key: string, value: any): Promise<void> {
    return Preferences.set({ key, value: JSON.stringify(value) });
  }


  async get(key: string): Promise<any> {
    const { value } = await Preferences.get({ key: key });
    const data = JSON.parse(value);
    return data;
  }


  async getItem(key: string): Promise<any> {
    const { value } = await Preferences.get({ key });
    return value;
  }

  async remove(key: string): Promise<void> {
    await Preferences.remove({key});
  }
}
