import { DdvEffects } from './ddv.effects';
import { UsersEffects } from "./user.effects";
import { LogosEffects } from "./logos.effects";
import { NumeroSorteEffects } from './numero-sorte.effects';

export const EffectsArray: any[] = [
  DdvEffects,
  UsersEffects,
  LogosEffects,
  NumeroSorteEffects,
];