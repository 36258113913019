import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions';

export interface LogosState { items: any; error: any; loading: boolean; }

export const logosState: LogosState = { loading: false, items: null, error: null };

const logosReducerMap = createReducer(
  logosState,

  on(actions.loadLogos, (state) => ({ ...state, loading: true })),

  on(actions.loadedLogos, (state, { items }) => ({ ...state, loading: false, items })),

  on(actions.errorLogos, (state, { error }) => ({ ...state, loading: false, error })),
);

export const logosReducer = (state: any, action: any) =>
logosReducerMap(state, action);