import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import localePt from '@angular/common/locales/pt';
import { MarkdownModule, MarkdownService } from 'ngx-markdown';

registerLocaleData(localePt);

@NgModule({
  declarations: [],
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    MarkdownModule.forRoot({ loader: HttpClient })
  ],
  providers: [
    MarkdownService
  ]
})
export class ConfigCoreModule { }
