export const environment = {
  production: true,
  update: 'https://api.c9t6aobb6f-condorsup1-d1-public.model-t.cc.commerce.ondemand.com/occ/v2/condor/psl/update/customer',
  hash: 'BD89AABF66A693538C48DE1BD4FE6',
  sap: {
    prefixed: 'CondorScreenSet',
    regisrationLogin: {
      sufixed: 'RegistrationLogin',
      container: {
        login: 'gigya-login-screen',
        register: 'gigya-register-screen',
        forgotPassword: 'gigya-forgot-password-screen',
      }
    },
    profileUpdate: {
      sufixed: 'ProfileUpdate',
      container: {
        profileUpdate: 'gigya-update-profile-screen',
        resetPassword: 'gigya-change-password-screen',
      }
    },
  },
  micro: 'https://micro.cndr.me',
  pathProdutos: 'https://api.cndr.me/images/png',
  url: 'https://api.cndr.me/api/v1',
  url2: 'https://us-central1-clube-condor-1050f.cloudfunctions.net/webApi/api/v1',
  pathClube: './assets/images/v2/',
  mkt: {
    url: 'https://marketing.condor.com.br/api'
  },
  gs: {
    url:  'https://us-central1-clube-condor-1050f.cloudfunctions.net/webApi/api/v1/',
    url2: 'https://marketing.condor.com.br/v3/',
    headers: {
      Authorization: '',
      Connection: 'keep-alive',
      'Content-Type': 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      'idvarejista': 'dw-zbd-condor-curitiba-pr',
      'x-api-key': '53hXpGxRyo2FcKSxcbm2j8sumkITzJjTd6VOuDdf'
    },
  },
  api: 'https://painel.condor.com.br',
  news: 'https://teste.condor.com.br/news/wp-json/wp/v2',
  blog: 'https://teste.condor.com.br/news/wp-json/wp/v2',
  marketing: 'https://marketing.condor.com.br',
  condorWeb: 'https://www.condor.com.br',
  crypto: 'suave-kity',
  apiProdcutsCredential: {
    email: 'fernando.nunes@condor.com.br',
    password: 'clubeCondor123#@!'
  },
  apiProdcuts: 'https://api.cndr.me/api/v1',
  apriProductsHeaders: {
    Connection: 'keep-alive',
    Accept: '*/*',
    'Accept-Encoding': 'gzip, deflate, br'
  },
  apiLogs: 'https://api.cndr.me/api/v1/logs',
  zoomBoxLogin: 'https://apiapp.zoombox.com.br/v10/login',
  contaCliente: 'https://slv.condor.com.br/api-rc/thirdparty/gs/conta-cliente',
  zoomBoxLoginDev: 'https://apiapp.zoombox.com.br/dev/login',
  zoomBox: 'https://apiapp.zoombox.com.br/v10',
  zoomBoxApiappBox: 'https://apiapp.zoombox.com.br/api/v1',
  zoomBoxV1: 'https://api.zoombox.com.br/v1',
  gs1: {
    url: 'https://us-central1-clube-condor-1050f.cloudfunctions.net/webApi/api/v1',
    url2: 'https://marketing.condor.com.br/v3'
  },
  firebase: {
    appUser: 'https://us-central1-clube-condor-1050f.cloudfunctions.net/appUser',
    api: 'https://us-central1-clube-condor-1050f.cloudfunctions.net/api'
  },
  cryptoJsKey: 'y/B?E(H+MbQeShVmYq3t6w9z$C&F)J@NcRfUjWnZr4u7x!A%D*G-KaPdSgVkYp2s5v8y/B?E(H+MbQeThWmZq4t6w9z$C&F)J@NcRfUjXn2r5u8x/A%D*G-KaPdSgVkYp3s6v9y$B&E(H+MbQeThWmZq4t7w!z%C*F-J@NcRfUjXn2r5u8x/A?D(G+KbPdSgVkYp3s6v9y$B&E)H@McQfThWmZq4t7w!z%C*F-JaNdRgUkXn2r5u8x/A?D(G+KbP',
  cryptoAux:  'HecVel',
  firebaseConfig : {
    apiKey: 'AIzaSyA5XBhejQBqm_AHvE9Igm442p2F8UX0jho',
    authDomain: 'clube-condor-1050f.firebaseapp.com',
    databaseURL: 'https://clube-condor-1050f.firebaseio.com',
    projectId: 'clube-condor-1050f',
    storageBucket: 'clube-condor-1050f.appspot.com',
    messagingSenderId: '156400804139',
    appId: '1:156400804139:web:d9948f7b494dbf6dc4256e',
    measurementId: 'G-QESRTEE4WP'
  },
  siteMercado: 'https://sm.condor.com.br/'
};
