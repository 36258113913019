import { Injectable } from '@angular/core';
import { NavController, AlertController, ModalController, LoadingController,
  AlertOptions, ModalOptions, LoadingOptions, ToastController, ToastOptions } from '@ionic/angular';
import { Share, ShareOptions } from '@capacitor/share';
import { Device, DeviceInfo } from '@capacitor/device';

@Injectable({ providedIn: 'root' })
export class UtilsService {

  constructor(
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private loadCtrl: LoadingController,
  ) {}

  navigate(url) {
    return this.navCtrl.navigateRoot(url);
  }

  alert = async (opts: AlertOptions): Promise<void> => {
    const item: HTMLIonAlertElement = await this.alertCtrl.create(opts);
    item.present();
  };

  modal = async (opts: ModalOptions): Promise<void> => {
    const item: HTMLIonModalElement = await this.modalCtrl.create(opts);
    item.present();
  };

  modalDimiss = () => this.modalCtrl.dismiss();

  load = async (opts: LoadingOptions): Promise<void> => {
    const item: HTMLIonLoadingElement = await this.loadCtrl.create(opts);
    item.present();
  };

  loadDimiss = () => this.loadCtrl.dismiss();

  toast = async (opts: ToastOptions): Promise<void> => {
    const item: HTMLIonToastElement = await this.toastCtrl.create(opts);
    item.present();
  };

  toastDimiss = () => this.toastCtrl.dismiss();

  async shareContent(data: ShareOptions) {
    const info: DeviceInfo = await Device.getInfo();
    if (info.platform === 'web') {
      await Share.share(data);
    }
  }


}
