import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UtilsService } from '@core/services/utils.service';
import { MasterService } from '@core/services/master.service';
import { StorageService } from '@core/services/storage.service';

@Component({
  selector: 'app-v2-fale-conosco',
  templateUrl: './fale-conosco.component.html',
  styleUrls: ['./fale-conosco.component.scss'],
})
export class FaleConoscoV2Component implements OnInit {
  loja: any = [];
  fale: any = [];
  formSAC: FormGroup;

  constructor(
    private fb: FormBuilder,
    private ms: MasterService,
    private uService: UtilsService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.load();
  }

  onClose() {
    this.uService.modalDimiss();
  }

  async onSubmit() {
    // let data: any = [];
    if (this.formSAC.invalid) return;
    const value = this.formSAC.value;
    const user = await this.storageService.get('oUser');
    if (user) {
      const data: any = {
        idloja: user.data.favoriteStore._id,
        celular: user,
        email: user.profile.email,
        mensagem: value.mensagem,
      };
      this.ms.postMaster('sendfaleconosco', data).subscribe(
        async (res) => this.setAlert()
      );
    }
    // const data = {
    //   idloja: value.loja.id,
    //   loja: value.loja.nome,
    //   mensagem: value.mensagem,
    //   idFaleConosco: value.assunto.id,
    //   tipocontato: value.assunto.descricao,
    //   email: res.email,
    //   celular: res.celular,
    //   nome: res.nome,
    //   cpf: res.cpfcnpj,
    // };
    // get('_user').then((res) => {
    //   this.ms.postMaster('sendfaleconosco', data).subscribe(
    //     async (res) => {
    //       await this.uService.alert({
    //         header: 'Show!',
    //         message: '',
    //         buttons: ['OK'],
    //       });
    //     }
    //   )
    // });
  }

  private async setAlert() {
    await this.uService.alert({
      header: 'Show!',
      message: '',
      buttons: [
        {
          text: 'OK', role: 'confirm',
          handler: () => this.uService.modalDimiss(),
        },
      ],
    });
  }

  load() {
    this.formSAC = this.fb.group({
      mensagem: ['', Validators.required],
    });
  }
}
