import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/services/auth.guard';
const app: Routes = [
  {
    path: '',
    redirectTo: '/pages/home',
    pathMatch: 'full'
  },
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@module/pages/pages.module').then((m) => m.PagesPageModule),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('@module/users/users.module').then( m => m.UsersModule)
  },
];

export const AppRoutes = RouterModule.forRoot(app, {
  preloadingStrategy: PreloadAllModules,
  onSameUrlNavigation: 'reload'
})