import { Component } from '@angular/core';
import { UtilsService } from '@core/services/utils.service';
import { ProfilePage } from '@module/users/pages/profile/profile.page';
import { RegulamentoV2Component } from '@module/conteudo/regulamento/regulamento.component';
import { FaleConoscoV2Component } from '@module/conteudo/fale-conosco/fale-conosco.component';
import { ChangePasswordComponent } from '@module/users/pages/login/change-password/change-password.component';
import { Observable } from 'rxjs';
import { MasterService } from '@core/services/master.service';
import { StorageService } from '@core/services/storage.service';

@Component({
  selector: 'app-v2-menu-painel',
  templateUrl: './menu-painel.component.html',
  styleUrls: ['./menu-painel.component.scss'],
})
export class MenuPainelComponent {

  constructor(
    private uService: UtilsService,
    private storageService: StorageService,
  ) {}

  async openChangePassword(): Promise<void> {
    await this.uService.modal({
      mode: 'ios',
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      component: ChangePasswordComponent,
    });
  }

  async openProfile(): Promise<void> {
    await this.uService.modal({
      mode: 'ios',
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      component: ProfilePage,
      componentProps: { header: false }
    });
  }

  async openFaleConosco(): Promise<void> {
    await this.uService.modal({
      mode: 'ios',
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      component: FaleConoscoV2Component,
      componentProps: { header: false }
    });
  }

  async logout() {
    await this.storageService.remove('oToken');
    await this.storageService.remove('oUser');
    this.uService.navigate('user/login');
  }

  async openConteudo(title: string): Promise<void> {
    await this.uService.modal({
      mode: 'ios',
      initialBreakpoint: 1,
      breakpoints: [0, .50, 1],
      component: RegulamentoV2Component,
      componentProps: { title }
    });
  }



}
