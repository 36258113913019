import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { IonicModule } from '@ionic/angular';
import { MenuPainelComponent } from './menu-painel.component';
import { ProfilePageModule } from '@module/users/pages/profile/profile.module';
import { ConteudoModule } from '@module/conteudo/conteudo.module';

@NgModule({
  declarations: [MenuPainelComponent],
  exports: [MenuPainelComponent],
  imports: [
    IonicModule,
    CommonModule,
    RouterModule,
  ]
})
export class MenuPainelModule { }
