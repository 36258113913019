import { ActionReducerMap } from '@ngrx/store';
import * as reducers from '@store/reducers';

export interface StoreState {
  ddv: reducers.DdvState;
  user: reducers.UserState;
  logos: reducers.LogosState;
  ns: reducers.NumSorteState;
}

export const ROOT_REDUCERS: ActionReducerMap<StoreState> = {
  ns: reducers.nsReducer,
  ddv: reducers.ddvReducer,
  user: reducers.userReducer,
  logos: reducers.logosReducer,
};
